import Layout from "../../../layouts/Layout";
import logo from "../../../storage/logo.png";
import { useOrderGateway } from "../core/OrderGatewayProvider";

export default function OrderGatewaySuccess({ type }) {
	type = type || "normal";

	const {
		order: { student },
	} = useOrderGateway();

	return (
		<Layout>
			<div className="max-w-5xl w-full mx-auto my-auto pt-20 pb-5 px-4 md:px-10 text-center rounded-xl">
				<div className="max-w-full w-full mx-auto my-auto pt-20 pb-5 text-center rounded-xl bg-white text-black relative">
					<header className="absolute top-[-67.5px] w-full">
						<img src={logo} alt="logo" className="mx-auto" />
					</header>
					<div className="px-4">
						<strong className="text-2xl font-semibold mb-4 block">תודה על זמנך!</strong>
						{type === "workers-committee" && <WorkersCommitteeSuccessMessage />}
						{type === "normal" && <NormalSuccessMessage />}
					</div>
				</div>
			</div>
		</Layout>
	);
}

const WorkersCommitteeSuccessMessage = () => {
	const {
		order: { student },
	} = useOrderGateway();

	return (
		<>
			<p className="text-lg font-light mb-4">
				שלום {student?.contact_information?.mother_name} ו{student?.contact_information?.father_name},
				<br />
				תודה על תשלום דמי הרישום להבטחת מקום ילדכם בקייטנה.
				<br />
				כעת עליכם להרשם דרך ועד העובדים אותו ציינתם בשלבי ההרשמה ולהשלים את יתרת התשלום באמצעות ועד זה.
				<br />
				<br />
				על מנת לבצע זאת עליכם למצוא אותנו בפורטל הקייטנות של הועד לפי קוד הקייטנה המתאים למחזור הפעילות בו בחרתם:
				<br />
				מחזור ראשון - קוד קייטנה 653
				<br />
				מחזור שני - קוד קייטנה 654
				<br />
				מחזור שלישי - קוד קייטנה 655
			</p>

			<p className="block mt-4 text-sm">
				זו ההזדמנות להזכירכם לפרגן לחברים ולשתפם בחווית הקייטנה שלנו.
				<br />
				בכל שאלה - אנחנו כאן בשבילכם בטלפון{" "}
				<a href="tel:0544781055" className="text-blue-400">
					054-4781055
				</a>
				, או באתר{" "}
				<a href="https://www.funkid-k.com" target="_blank" rel="noreferrer" className="text-blue-400">
					www.funkid-k.com
				</a>
			</p>
		</>
	);
};

const NormalSuccessMessage = () => {
	const {
		order: { student },
	} = useOrderGateway();

	return (
		<>
			<p className="block">
				כל התשלומים הושלמו בהצלחה.
				<br />
				מספר הרישום של <strong className="font-bold">{student.first_name + " " + student.last_name}</strong> הוא {student.id}
			</p>
			<p className="block mt-4 text-sm">
				זו ההזדמנות להזכירכם לפרגן לחברים ולשתפם בחווית הקייטנה שלנו.
				<br />
				בכל שאלה - אנחנו כאן בשבילכם בטלפון{" "}
				<a href="tel:0544781055" className="text-blue-400">
					054-4781055
				</a>
				, או באתר{" "}
				<a href="https://www.funkid-k.com" target="_blank" rel="noreferrer" className="text-blue-400">
					www.funkid-k.com
				</a>
			</p>
			<div className="mt-10 mb-4">
				<a href="/" className="rounded-3xl bg-blue-500 text-white py-3 px-6">
					חזרה לאתר
				</a>
			</div>
		</>
	);
};
